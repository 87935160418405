// export let baseUrl = "http://localhost:4000";
export let baseUrl = "https://stagingapiv2.gamebeez.in";

// const type = "STAGEMAIN";
const type = "PROD";
// const type = "DEV";

if (type === "PROD") {
  baseUrl = "https://apiv2.gamebeez.in";
} else if (type === "STAGEMAIN") {
  baseUrl = "https://stagingapiv2.gamebeez.in";
} else {
  // baseUrl = "https://stagingapiv2.gamebeez.in";
  baseUrl = "http://localhost:4000";
}

export const APIurls = {
  getRoles: `${baseUrl}/api/v2/get-roles`,

  //Profile
  fetchProfileDetails: `${baseUrl}/api/clubs/tournament/profile`,

  // staff Records
  fetchStaffRecords: `${baseUrl}/api/clubs/tournament/staffRecords`,

  //Creation of Tournament API's//
  fetchCategories: `${baseUrl}/api/clubs/tournament/allCategories`,
  fetchAllTournamentSports: `${baseUrl}/api/clubs/tournament/getSports`,
  tournamentCreation: `${baseUrl}/api/clubs/tournament/create`,
  fetchAllClubs: `${baseUrl}/api/clubs/tournament/clubFilter`,

  //All, Ongoing, Upcoming, Completed and Archived Related API's//
  fetchAllTournamentsBasedOnStatus: `${baseUrl}/api/clubs/tournament/filter`,
  deleteTournamentByID: `${baseUrl}/api/clubs/tournament/archivedTournament`,
  deleteTournamentAtArchive: `${baseUrl}/api/clubs/tournament/permanentlyDeleteTournament`,
  recoverTournamentFromArchive: `${baseUrl}/api/clubs/tournament/recoverTournament`,

  //Over View of Tournament and Creation of Participants Sections Related API's//
  fetchOverViewOfTournament: `${baseUrl}/api/clubs/tournament/getTournamentOverview`,
  updateTournamentDetails: `${baseUrl}/api/clubs/tournament/updateDetails`,
  updateScoreType: `${baseUrl}/api/clubs/tournament/updateScoreType`,

  //Venue Related API's//
  fetchVenues: `${baseUrl}/api/clubs/tournament/venue/getVenues`,
  fetchDropDownDataForAddingVenues: `${baseUrl}/api/clubs/tournament/venue/fetchOwnerClubs`,
  createVenueForTournament: `${baseUrl}/api/clubs/tournament/venue/create`,
  fetchSpecificVenueDetails: `${baseUrl}/api/clubs/tournament/venue/specificVenue`,
  updateVenueDetails: `${baseUrl}/api/clubs/tournament/venue/editVenue`,
  deleteSpecificVenue: `${baseUrl}/api/clubs/tournament/venue/deleteVenue`,

  //Team and Player Related API's//
  fetchParticipants: `${baseUrl}/api/clubs/tournament/participant/getParticipants`,
  addParticipantIntoTournament: `${baseUrl}/api/clubs/tournament/addParticipant`,
  deleteParticipantFromTournament: `${baseUrl}/api/clubs/tournament/removeParticipant`,
  editTeamName: `${baseUrl}/api/clubs/tournament/participant/team/editName`,
  fetchExistingTeamDetails: `${baseUrl}/api/clubs/tournament/participant/getSpecificParticipant`,
  fetchPlayersFromSpecificTeam: `${baseUrl}/api/clubs/tournament/participant/team/viewTeam`,
  addPlayerIntoTeam: `${baseUrl}/api/clubs/tournament/participant/team/addPlayer`,
  removePlayerFromTeam: `${baseUrl}/api/clubs/tournament/participant/team/removeParticipant`,
  fetchPlayerDetailsByPhoneNumber: `${baseUrl}/api/clubs/tournament/participant/fetchPlayerByPhoneNumber`,
  saveIndividualPlayerDetails: `${baseUrl}/api/clubs/tournament/participant/player/edit`,

  //Organizer Related API's
  addOrganizer: `${baseUrl}/api/clubs/tournament/sponsor/create`,
  fetchAllOrganizers: `${baseUrl}/api/clubs/tournament/sponsor/getSponsors`,
  updateSponsorOrOrganizer: `${baseUrl}/api/clubs/tournament/sponsor/updateSponsor`,
  fetchSpecificSponsorOrOrganizer: `${baseUrl}/api/clubs/tournament/sponsor/getSpecificSponsor`,
  deleteSpecificSponsor: `${baseUrl}/api/clubs/tournament/sponsor/removeSponsor`,

  //Game Fixtures Section Related API's//
  fetchGameFixtures: `${baseUrl}/api/clubs/tournament/rounds/gameFixtures`,
  fetchRoundModalDataToArranged: `${baseUrl}/api/clubs/tournament/round/fixing/getParticipants`,
  setArrangedTeamsIntoMatches: `${baseUrl}/api/clubs/tournament/round/fixing/setParticipants`,
  fetchRoundRobbinStanding: `${baseUrl}/api/clubs/tournament/RoundRobbin/getPointsTable`,
  fetchMatchDetailsById: `${baseUrl}/api/clubs/tournament/match/getMatchDetails`,
  updateTournamentMatchSetsNumber: `${baseUrl}/api/clubs/tournament/match/updateNoOfSets`,
  updateTournamentMatchScores: `${baseUrl}/api/clubs/tournament/match/updateMatchScores`,
  updateTournamentMatchCricketScores: `${baseUrl}/api/clubs/tournament/match/updateMatchScores/cricket`,
  EditMatchDetailsInGameFixtures: `${baseUrl}/api/clubs/tournament/match/editMatchDetails`,
  updateWinnerById: `${baseUrl}/api/clubs/tournament/knockout/updateWinner`,
  updateWinnerForDoubleKnockoutById: `${baseUrl}/api/clubs/tournament/doubleKnockout/updateWinner`,
  updateWinnerForRoundRobbinById: `${baseUrl}/api/clubs/tournament/RoundRobbin/updateWinner`,
  saveMatchTossWinner : `${baseUrl}/api/clubs/tournament/match/tossWinner`,
  saveFootballScores: `${baseUrl}/api/clubs/tournament/match/updateMatchScores/football`
};