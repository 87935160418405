import React, { useEffect, useState } from 'react';
import gamebeezClubLogo from '../assets/clubPartnerAppIcon.svg'
import { useAuth } from "../store/AuthContext";
import { useNavigate } from 'react-router-dom';
import { FaRegCircleUser } from "react-icons/fa6";
import { RiLogoutBoxLine } from "react-icons/ri";

function Navbar() {

  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <nav className="bg-white flex justify-end px-[2rem]">
        <div className="w-[100%] flex justify-between items-center">
          <div className="w-[85px] h-[85px] flex justify-center items-center">
            <img src={gamebeezClubLogo} alt='GamebeezClubLogo' className="rounded-2xl w-[60px] h-[60px]" />
          </div>
          <div className='flex gap-2  rounded-md p-2 my-2 '>
            <div className='flex items-center gap-1 hover:text-primary'>
              <FaRegCircleUser />
              <p
                className='cursor-pointer'
                onClick={() => navigate("profile")}
              >
                Profile
              </p>
            </div>
            <hr className='h-[2px] bg-gray-300'></hr>
            <div className='flex items-center gap-1 hover:text-primary'>
              <RiLogoutBoxLine />
              <p
                className='cursor-pointer'
                onClick={() => logout()}
              >
                Logout
              </p>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;