import React, { useEffect, useState } from "react";
import TournamentCategories from "./TournamentCategories";
import { NavLink, Outlet } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import Select from "react-select";
import { useAuth } from "../../store/AuthContext";
import { APIurls } from "../../api/apiConstant";
import { toast } from "react-toastify";

export default function Tournament() {

  const [club, setClub] = useState({ value: "", label: "" });
  const [mainCategorySport, setMainCategorySport] = useState({ value: "", label: "" });
  const [subCategorySport, setSubCategorySport] = useState({ value: "", label: "" });
  const [sport, setSport] = useState({ value: "", label: "" });
  const [clubOptions, setClubOptions] = useState([]);
  const [mainCategoryData, setMainCategoryData] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [sportOptions, setSportOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  const { getAccessToken, userRole } = useAuth();

  const fetchClubs = async () => {
    try {
      setLoader(true);
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllClubs}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (result?.code > 201) {
        throw new Error(result.message);
      }
      const clubs = result.response.clubs;
      if (userRole?.role?.staff?.type) {
        const obj = {
          value: clubs?.length > 0 && clubs[0]?.id,
          label: clubs?.length > 0 && clubs[0]?.clubName,
          value: clubs?.length > 0 ? clubs[0]?.id :"",
          label: clubs?.length > 0 ? clubs[0]?.clubName : "",
        };
        setClub(obj);
      }
      const clubOpt = result?.response?.clubs?.map((item) => ({
        value: item?.id,
        label: item?.clubName,
      }));
      setClubOptions(clubOpt);
    } catch (error) {
      toast.error(error.message);
      console.log("Error while fetching clubs", error.message);
    }
    setLoader(false);
  };

  const fetchMainCategories = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchCategories}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (result?.code > 201) {
        throw new Error(result?.message)
      }
      // console.log("Categories--->", result?.response?.categories);
      const data = result?.response?.categories
      setMainCategoryData(data);
    } catch (error) {
      toast.error(error.message)
      console.log("Error While fetching Main Categories", error?.message)
    }
  }

  const fetchAllSports = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllTournamentSports}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (result?.code > 201) {
        throw new Error(result.message)
      }
      // console.log("Sports---------->", result.response?.tournamentSports)
      const sportOpt = result?.response?.tournamentSports?.map((item) => ({
        value: item?._id,
        label: item?.name
      }));
      setSportOptions(sportOpt);
    }
    catch (error) {
      toast(error.message)
      console.log("Error while fetching Sports", error.message)
    }
  }

  useEffect(() => {
    fetchClubs();
    fetchMainCategories();
    fetchAllSports();
  }, []);

  const mainCategoryOptions =
    mainCategoryData?.map(category => ({
      value: category?._id,
      label: category?.categoryName
    }))

  useEffect(() => {
    console.log('inside use : ',mainCategorySport)
    if (mainCategorySport) {
      console.log('inside condition ',mainCategorySport);
      const mainCat = mainCategoryData?.find((div) => div?._id === mainCategorySport?.value);
      if (Array.isArray(mainCat?.subCategoriesDetails)) {
        const subCatOptions = mainCat?.subCategoriesDetails?.map((div) => ({
          value: div?._id,
          label: div?.subCategoryName
        }));
        setSubCategoryOptions(subCatOptions);
      }
    }
    console.log('out side condition');
    // else{
    //   setMainCategorySport({ value : "" , label : ""});
    // }
    setSubCategorySport({ value: "", label: "" });
  }, [mainCategorySport]);

  return (
    <div className="p-4 h-screen">
      <div className="w-full flex flex-col px-[1rem] py-[1rem] bg-white">
        <div className="flex justify-end items-center gap-3">
          <NavLink to="/tournaments/create">
            <button className="flex items-center gap-2 text-white bg-primary p-2 rounded-md">
              Create Tournament
              <BsPlusLg className="text-white cursor-pointer" />
            </button>
          </NavLink>
        </div>
        {loader ? <p className="text-2xl font-semibold text-center">Loading...</p>
          :
          <div className="flex justify-center gap-[1rem] pt-[1.5rem] flex-wrap">
            <Select
              options={clubOptions}
              placeholder="Select Club"
              className={`w-[250px]  ${userRole?.role?.staff?.type ? "cursor-not-allowed" : ""}`}
              value={club?.value ? club : ""}
              isClearable={true}
              onChange={(selectedOption) => setClub(selectedOption)}
              isDisabled={userRole?.role?.staff?.type}
            >
            </Select>
            <Select
              options={mainCategoryOptions}
              placeholder="Select Main-Category"
              className={`w-[250px]`}
              value={mainCategorySport?.value ? mainCategorySport : ""}
              isClearable={true}
              onChange={(selectedOption) => setMainCategorySport(selectedOption)}
            >
            </Select>
            <Select
              options={subCategoryOptions}
              placeholder="Select Sub-Category"
              className={`w-[250px]`}
              value={subCategorySport?.value ? subCategorySport : ""}
              isClearable={true}
              onChange={(selectedOption) => setSubCategorySport(selectedOption)}
            >
            </Select>
            <Select
              options={sportOptions}
              placeholder="Select Sport"
              className={`w-[250px]`}
              value={sport?.value ? sport : ""}
              isClearable={true}
              onChange={(selectedOption) => setSport(selectedOption)}
            >
            </Select>
          </div>
        }
        <TournamentCategories />
        <Outlet context={[club?.value ? club?.value : "",
        mainCategorySport?.value ? mainCategorySport?.value : "",
        subCategorySport?.value ? subCategorySport?.value : "",
        sport?.value ? sport?.value : "",]}
        />
      </div>
    </div>
  );
}
