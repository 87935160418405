import React, { useState } from 'react'
import { Icon } from "@iconify/react";
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from 'react-data-table-component';
import cameraImg from '../../../assets/camereImg.jpg'
import { NavLink, useParams } from 'react-router-dom';
import EditVenueModal from './EditVenueModal'
import OverViewAddTeamModal from './OverViewAddTeamModal';
import OverViewEditTeamModal from './OverViewEditTeamModal';
import DeletePartcipantModal from './DeleteParticipantModal';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useQuery } from 'react-query';
import AboutTournamentModal from './AboutTournamentModal';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import OverViewEditPlayerModal from './OverViewEditPlayerModal';
import { ThreeDots } from 'react-loader-spinner';

export default function OverviewOfTournament() {

    const [AddTeamModal, setAddTeamModal] = useState(false);
    const [editVenueModal, setEditVenueModal] = useState(false);
    const [editTeamModal, setEditTeamModal] = useState(false);
    const [venueId, setVenueId] = useState("");
    const [participantNumber, setPartcipantNumber] = useState(0);
    const [selectionType, setSelectionType] = useState("");
    const [participantId, setPartcipantId] = useState("");
    const [deleteParticipantModal, setDeletPartcipantModal] = useState(false);
    const [aboutTournamentModal, setAboutTournamentModal] = useState(false);
    const [aboutTournament, setAboutTournament] = useState("");
    const [editPlayerModal, setEditPlayerModal] = useState(false);
    const [isVenueDelete, setIsVenueDelete] = useState({});
    const [isSponsorDelete, setIsSponsorDelete] = useState({});

    const { tournamentId } = useParams();
    const { getAccessToken, userRole } = useAuth();

    const fetchTournamentDetails = async () => {
        try {
            const token = await getAccessToken();
            // setLoader(true);
            const response = await fetch(`${APIurls.fetchOverViewOfTournament}/${tournamentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const result = await response.json();
                throw new Error(result.message)
            }
            const result = await response.json();
            return result?.response
        } catch (error) {
            console.log("Error while fetching tournament details", error.message)
        }
        // setLoader(false);
    }
    const { data: OverViewTournamentDetails, refetch: tournamentRefetch, isLoading } = useQuery(["OverViewDetails", tournamentId], fetchTournamentDetails)
    // console.log("OverView of Tournament Details", OverViewTournamentDetails?.tournamentDetails)

    const fetchAllParticipants = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchParticipants}/${tournamentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            // console.log("Participants Data--->", result?.response)
            const typeOfSelection = result?.response?.selectionType
            setSelectionType(typeOfSelection);
            const participantLength = result?.response?.participants?.length
            setPartcipantNumber(participantLength);
            return result?.response
        } catch (error) {
            console.log("Error in fetching participants", error.message)
        }
    }

    const { data: participantsData, isLoading: isParticipantsLoading, refetch: participantsrefetch } = useQuery("fetchParticipantsData", fetchAllParticipants)
    // console.log("Participants Data--->", participantsData?.participants)

    const fetchTournamentVenues = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchVenues}/${tournamentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            // console.log("Venues--->", result)
            return result?.response?.venues
        } catch (error) {
            console.log("Error while fetching venues", error.message)
        }
    }
    const { data: venuesData, isLoading: isVenuesLoading, refetch: venueRefetch } = useQuery('venues', fetchTournamentVenues)
    console.log("Venues Data", venuesData)

    const handleAddTeamShowModal = () => {
        setAddTeamModal(true)
    }

    const handleEditVenueModal = (id) => {
        setEditVenueModal(true)
        setVenueId(id)
    }

    const handleEditTeamNameModal = (id) => {
        setEditTeamModal(true)
        setPartcipantId(id)
    }

    const handleDeleteVenue = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        let isConfirm = confirm("Are you sure you want to Delete this Venue?");
        if (isConfirm) {
            try {
                setIsVenueDelete((prevState) => ({
                    ...prevState,
                    [id]: true,
                }))
                const token = await getAccessToken();
                const response = await fetch(`${APIurls.deleteSpecificVenue}/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                const result = await response.json();
                if (!response.ok) {
                    console.log(result.message)
                    toast.error(result.message)
                    return;
                }
                console.log("Venue Deleted Successfully", result?.response)
                venueRefetch();
                toast.success("Venue Deleted Successfully")
            } catch (error) {
                toast.error("Error while deleting the venue");
                console.log("Error while deleting venue", error)
            }
            setIsVenueDelete((prevState) => (
                {
                    ...prevState,
                    [id]: false,
                }))
        }
    }

    const fetchSponsors = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAllOrganizers}/${tournamentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            // console.log("Sponsors Data--->", result?.response?.Sponsors)
            return result?.response?.Sponsors
        } catch (error) {
            console.log("Error while fetching sponsors", error.message)
        }
    }

    const { data: sponsorsData, refetch: sponsorRefetch, isLoading: isSponsorLoading } = useQuery(["getSponsors", tournamentId], fetchSponsors)

    const handleDeleteSpecificSponsor = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        let isConfirm = confirm("Are you sure you want to Delete this Sponsor?");
        if (isConfirm) {
            try {
                setIsSponsorDelete((prevState) => ({
                    ...prevState,
                    [id]: true,
                }))
                const token = await getAccessToken();
                const response = await fetch(`${APIurls.deleteSpecificSponsor}/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                const result = await response.json();
                if (!response.ok) {
                    console.log(result.message)
                    toast.error(result.message)
                }
                console.log("Sponsor Deleted Successfully", result?.response)
                sponsorRefetch();
                toast.success("Sponsor Deleted Successfully")
            } catch (error) {
                console.log("Error in deleting sponsor", error)
            }
            setIsSponsorDelete((prevState) => (
                {
                    ...prevState,
                    [id]: false,
                }))
        }
    }

    const handleDeletePartcipantModal = (id) => {
        setPartcipantId(id)
        setDeletPartcipantModal(true)
    }

    const handleShowMoreModal = () => {
        setAboutTournamentModal(true)
        setAboutTournament(OverViewTournamentDetails?.tournamentDetails?.description)
    }

    const handleEditPlayerModal = (id) => {
        setEditPlayerModal(true)
        setPartcipantId(id)
    }

    const columns1 = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            width: "80px",
            center: true
        },
        {
            name: "Venue Name",
            selector: row => (
                <div>{row?.venueName}</div>
            ),
            center: true,
            grow: 1
        },
        {
            name: "Club ID",
            selector: row => (
                <div>{row?.venueClubId}</div>
            ),
            center: true,
            grow: 1.5
        },
        {
            name: "Club Location",
            selector: row => (
                <div>{row?.clubLocation?.City}</div>
            ),
            center: true
        },
        {
            name: "Date Added",
            selector: row => (
                <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
            ),
            center: true
        },

        {
            name: "Updated Date",
            selector: row => (
                <div>{moment(row?.updatedAt).format("MMM Do YY")}</div>
            )
        },
        userRole?.role?.clubOwner && {
            name: "Action",
            selector: row => (
                <div className="flex gap-[0.3rem]">
                    <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer"
                        onClick={() => handleEditVenueModal(row?._id)}
                    >
                        <Icon icon="material-symbols:edit-rounded" className="text-[1.5rem] text-black" />
                    </div>
                    {
                        isVenueDelete[row?._id] ? (
                            <ThreeDots
                                height="40"
                                width="40"
                                radius="9"
                                color="#FB6108"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        ) : (
                            <div onClick={() => handleDeleteVenue(row?._id)}
                                className=" border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer"
                            >
                                <Icon
                                    icon="material-symbols:delete-rounded"
                                    className="text-[1.5rem] text-black"
                                />
                            </div>
                        )}
                </div>
            ),
            center: true
        }
    ]

    const columns2 = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            width: "80px",
            center: true
        },
        {
            name: "List of Teams",
            selector: row => (
                <div>{row?.name}</div>
            ),
            center: true
        },
        {
            name: "Date Added",
            selector: row => (
                <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
            ),
            center: true
        },
        {
            name: "Updated Date",
            selector: row => (
                <div>{moment(row?.updatedAt).format("MMM Do YY")}</div>
            )
        },
        {
            name: "Actions",
            selector: row => (
                <div className="flex gap-[0.3rem]">
                    <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer"
                        onClick={() => handleEditTeamNameModal(row?._id)}
                    >
                        <Icon icon="material-symbols:edit-rounded" className="text-[1.5rem] text-black" />
                    </div>
                    <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/viewParticipants/${row?._id}`}>
                        <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer">
                            <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
                        </div>
                    </NavLink>
                    <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer"
                        onClick={() => handleDeletePartcipantModal(row?._id)}
                    >
                        <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem] text-black" />
                    </div>
                </div>
            ),
            center: true
        }
    ]

    const columns3 = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            center: true,
            width: "80px"
        },
        {
            name: "Player Name",
            selector: row => (
                <div>
                    <p>Name:- {row?.name}</p>
                    <p>Phone:- {row?.phone === null ? "NA" : row?.phone}</p>
                    <p>Email:- {row?.email === null ? "NA" : row?.email}</p>
                </div>
            ),
            center: true,
            // grow: 2.5
        },
        {
            name: "Date Added",
            selector: row => (
                <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
            ),
            center: true
        },
        {
            name: "Updated Date",
            selector: row => (
                <div>{moment(row?.updatedAt).format("MMM Do YY")}</div>
            ),
            center: true
        },
        {
            name: "Action",
            selector: row => (
                <div className='flex items-center gap-2'>
                    <div
                        className="p-2 cursor-pointer bg-white text-black border border-[#D9D9D9] rounded-lg"
                        onClick={() => handleEditPlayerModal(row?._id)}
                    >
                        <Icon
                            icon="material-symbols:edit-rounded"
                            className="text-[1.5rem]"
                        />
                    </div>
                    <div
                        className="p-2 cursor-pointer bg-white text-black border border-[#D9D9D9] rounded-lg"
                        onClick={() => handleDeletePartcipantModal(row?._id)}
                    >
                        <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem]" />
                    </div>
                </div>
            ),
            center: true,
            grow: 1.5
        }
    ]

    const columns4 = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            width: "80px",
            center: true
        },
        {
            name: "Organizer/Sponsor Name",
            selector: row => (
                <div className='flex items-center'>
                    {row?.name}
                    {
                        row?.defaultSponsor === true ? <p className='text-red-500'>*</p> : ""
                    }
                </div>
            ),
            center: true,
            grow: 2.5
        },
        {
            name: "Date Added",
            selector: row => (
                moment(row?.addedDate).format("MMM Do YY")
            ),
            center: true
        },
        {
            name: "Updated Date",
            selector: row => (
                moment(row?.updatedDate).format("MMM Do YY")
            ),
            center: true
        },
        {
            name: "Action",
            selector: row => (
                <div className="flex gap-[0.6rem]">
                    <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/editOrganizerDetails/${row?.sponsorID}`}>
                        <div
                            className="p-2 cursor-pointer bg-white text-black border border-[#D9D9D9] rounded-lg"
                        >
                            <Icon icon="material-symbols:edit-rounded" className="text-[1.5rem]" />
                        </div>
                    </NavLink>
                    {
                        isSponsorDelete[row?.sponsorID] ? (
                            <ThreeDots
                                height="40"
                                width="40"
                                radius="9"
                                color="#FB6108"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        ) : (
                            <div onClick={() => handleDeleteSpecificSponsor(row?.sponsorID)}
                                className="p-2 cursor-pointer bg-white text-black border border-[#D9D9D9] rounded-lg"
                            >
                                <Icon
                                    icon="material-symbols:delete-rounded"
                                    className="text-[1.5rem] text-black"
                                />
                            </div>
                        )}
                </div>
            ),
            center: true,
            grow: 3
        }
    ]

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                color: "#4D4D4D",
                fontSize: "13px",
                // background: "#EAECF0"
            },
        },
        head: {
            style: {
                background: "#EAECF0",
            },
        },

        rows: {
            style: {
                background: "#FFF",
                color: "#565656",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
            },
        },
        table: {
            style: {
                overflow: "visible",
                minWidth: "1100px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto",
            },
        },
    };

    return (
        <>
            {isLoading ?
                <p className='text-2xl font-semibold flex items-center justify-center min-h-[20vh]'>Loading...</p>
                :
                <div className='border border-gray-200 p-3 rounded-md mx-[2rem] my-[2rem]'>
                    <div className='flex justify-between px-4'>
                        <p className='text-xl font-semibold'>Tournament Details</p>
                        <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/editOverViewTournament`}>
                            <div className='flex items-center text-white bg-primary px-4 py-2 rounded-md gap-2 cursor-pointer'>
                                <button>Edit</button>
                                <Icon icon="material-symbols:edit-rounded" className="text-[1.2rem]" />
                            </div>
                        </NavLink>
                    </div>
                    <div className='flex justify-evenly p-4 max-xl:grid max-xl:grid-cols-3 max-xl:gap-8'>
                        <div className=''>
                            <img
                                className='w-[200px] h-[200px] object-fill cursor-pointer'
                                src={OverViewTournamentDetails?.tournamentDetails?.BannerImg?.length > 0 ? OverViewTournamentDetails?.tournamentDetails?.BannerImg[OverViewTournamentDetails?.tournamentDetails?.BannerImg?.length - 1] : cameraImg}
                            />
                        </div>
                        <div className='flex flex-col gap-6'>
                            <div className=''>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.mainCategoryName}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Main Category Sport</p>
                            </div>
                            <div>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.sportName}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Sport</p>
                            </div>
                            <div>
                                <p
                                    className='font-medium text-xl max-lg:text-[18px]'
                                    title={OverViewTournamentDetails?.tournamentDetails?.tournamentName}
                                >
                                    {OverViewTournamentDetails?.tournamentDetails?.tournamentName?.length > 20 ? OverViewTournamentDetails?.tournamentDetails?.tournamentName?.slice(0, 20) + "..." : OverViewTournamentDetails?.tournamentDetails?.tournamentName}
                                </p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Tournament Name</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-6'>
                            <div>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.subCategoryName}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Sub Category Sport</p>
                            </div>
                            <div>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.fixingType}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Fixing Type</p>
                            </div>
                            <div>
                                <div className=''>
                                    {OverViewTournamentDetails?.tournamentDetails?.description?.length > 20 ?
                                        <div className='flex items-center gap-1 max-lg:flex max-lg:flex-wrap'>
                                            <p className='max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.description?.substring(0, 15)}...</p>
                                            <p className='text-[0.7rem] font-medium hover:text-primary hover:cursor-pointer'
                                                onClick={handleShowMoreModal}
                                            >
                                                Show More
                                            </p>
                                        </div>
                                        :
                                        <p className='font-medium text-base max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.description === null ? "NA"
                                            : OverViewTournamentDetails?.tournamentDetails?.description}
                                        </p>
                                    }
                                </div>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>About Tournament</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-6'>
                            <div>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.tournamentType}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Tournament Type</p>
                            </div>
                            <div>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.startDate === null ? "NA" : moment(OverViewTournamentDetails?.tournamentDetails?.startDate).format("MMM Do YY")}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Start Date</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-6'>
                            <div>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.participants}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Partcipant Teams</p>
                            </div>
                            <div>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.endDate === null ? "NA" : moment(OverViewTournamentDetails?.tournamentDetails?.endDate).format("MMM Do YY")}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>End Date</p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className='font-medium text-xl max-lg:text-[18px]'>{OverViewTournamentDetails?.tournamentDetails?.format}</p>
                                <p className='text-[#808080] font-medium text-sm max-lg:text-[12px]'>Format</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='border border-gray-200 p-5 rounded-md mx-[2rem] my-[2rem]'>
                <div className='flex justify-between items-center px-4 py-2'>
                    <p className='text-xl font-semibold'>Venue</p>
                    <div className='flex items-center gap-4'>
                        {/* <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                            <Icon
                                icon='material-symbols:delete'
                                className='text-[1.5rem]'
                            />
                            <span className='text-[1rem] font-medium'>Remove</span>
                        </div> */}
                        {userRole?.role?.clubOwner &&
                            <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/createVenue`}>
                                <button className='flex items-center justify-center bg-primary text-white px-3 py-2 rounded-md'>
                                    Add Venue <AiOutlinePlus />
                                </button>
                            </NavLink>
                        }
                    </div>
                </div>
                <DataTable
                    data={venuesData}
                    columns={columns1}
                    customStyles={customStyles}
                    pagination
                    paginationPerPage={10}
                    selectableRows
                    progressPending={isVenuesLoading}

                />
            </div>
            <div className='border border-gray-200 p-5 rounded-md mx-[2rem] my-[2rem]'>
                <div className='flex justify-between px-4 py-2'>
                    <p className='text-xl font-semibold'>{participantsData?.selectionType === "team" ? "List of Teams" : "List of Players"}</p>
                    <div className='flex gap-4'>
                        <div className='flex items-center text-white bg-primary px-4 py-2 rounded-md gap-2 cursor-pointer'
                            onClick={handleAddTeamShowModal}
                        >
                            <button>{participantsData?.selectionType === "team" ? "Add Team" : "Add Player"}</button>
                            <AiOutlinePlus className="text-[1.2rem]" />
                        </div>
                    </div>
                </div>
                {
                    participantsData?.selectionType === "team" ?
                        <DataTable
                            data={participantsData?.participants?.sort((a, b) => a?.teamNumber - b?.teamNumber)}
                            columns={columns2}
                            customStyles={customStyles}
                            pagination
                            paginationPerPage={10}
                            selectableRows
                            progressPending={isParticipantsLoading}
                        />
                        :
                        <DataTable
                            data={participantsData?.participants?.sort((a, b) => a?.playerNumber - b?.playerNumber)}
                            columns={columns3}
                            customStyles={customStyles}
                            pagination
                            paginationPerPage={10}
                            selectableRows
                            progressPending={isParticipantsLoading}
                        />
                }
            </div>
            <div className='border border-gray-200 p-5 mx-[2rem] my-[2rem] rounded-md'>
                <div className='flex items-center justify-between px-4 py-2'>
                    <p className='text-xl font-semibold'>Organizer/Sponsor</p>
                    <div className='flex items-center gap-4'>
                        <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/addOrganizer`}>
                            <button className='flex items-center px-3 py-2 gap-1 bg-primary rounded-md text-white'>
                                <AiOutlinePlus />
                                Add Organizer/Sponsor
                            </button>
                        </NavLink >
                    </div>
                </div>
                <DataTable
                    data={sponsorsData}
                    columns={columns4}
                    customStyles={customStyles}
                    pagination
                    paginationPerPage={5}
                    selectableRows
                    progressPending={isSponsorLoading}
                />
                <div className='flex items-center text-[#344054]'>Note: "<p className='text-red-500 text-[20px]'>*</p>" indicates the default organizer/sponsor</div>
            </div>
            <ToastContainer />
            {AddTeamModal && (
                <OverViewAddTeamModal
                    setShowModal={setAddTeamModal}
                    tournamentId={tournamentId}
                    selectionType={selectionType}
                    number={participantNumber}
                    participantsrefetch={participantsrefetch}
                    tournamentRefetch={tournamentRefetch}
                />
            )}
            {editVenueModal && (
                <EditVenueModal
                    setEditVenueModal={setEditVenueModal}
                    venueId={venueId}
                    venueRefetch={venueRefetch}
                />
            )}
            {editTeamModal && (
                <OverViewEditTeamModal
                    setEditTeamModal={setEditTeamModal}
                    participantId={participantId}
                    selectionType={participantsData?.selectionType}
                    refetch={participantsrefetch}
                />
            )}
            {editPlayerModal && (
                <OverViewEditPlayerModal
                    setEditPlayerModal={setEditPlayerModal}
                    participantId={participantId}
                    selectionType={participantsData?.selectionType}
                    refetch={participantsrefetch}
                />
            )}
            {deleteParticipantModal && (
                <DeletePartcipantModal
                    partcipantModal={setDeletPartcipantModal}
                    participantId={participantId}
                    participantsrefetch={participantsrefetch}
                    tournamentRefetch={tournamentRefetch}
                    tournamentId={tournamentId}
                />
            )}
            {aboutTournamentModal && (
                <AboutTournamentModal
                    aboutTournament={aboutTournament}
                    setAboutTournamentModal={setAboutTournamentModal}
                />
            )}
        </>
    )
}
