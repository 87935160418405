import React, { useEffect, useState } from "react";
import { useAuth } from "../store/AuthContext";
import { APIurls } from "../api/apiConstant";
import DataTable from "react-data-table-component";
import { NavLink, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { Icon } from "@iconify/react";

function Profile() {

    const [ownerName, setOwnerName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [designation, setDesignation] = useState("");
    const [clubsData, setClubsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [clubName, setClubName] = useState("");
    const [isStaff, setIsStaff] = useState(true);

    const { getAccessToken } = useAuth();
    const navigate = useNavigate();

    const fetchProfileData = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchProfileDetails}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const result = await response.json();
                throw new Error(result?.message);
            }
            const result = await response.json();
            console.log("Profile Data--->", result?.response);
            setClubsData(result?.response?.clubs);
            setEmail(result?.response?.email);
            setIsStaff(result?.response?.clubOwner);
            if (result?.response?.clubOwner === false) {
                setClubName(result?.response?.clubs[0]?.clubName);
                // return;
            }
        } catch (error) {
            console.log("Error in Fetching Profile Details", error)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchProfileData();
    }, [])

    const columns = [
        {
            name: "SL",
            selector: (row, i) => i + 1,
            center: true,
            width: "80px"
        },
        {
            name: "Club ID",
            selector: row => (
                <div>{row?.ClubID}</div>
            ),
            center: true
        },
        {
            name: "Club Name",
            selector: row => (
                <div>{row?.clubName}</div>
            ),
            // center: true
        },
        {
            name: "Location",
            selector: row => (
                <div>{row?.City}</div>
            )
        },
        {
            name: "Designation",
            selector: row => (
                <div>{row?.designation}</div>
            )
        },
        {
            name: "Owner Details",
            selector: row => (
                <div>
                    <div>{row?.OwnerName}</div>
                    <div>{row?.mobileNumber}</div>
                    <div>{row?.email}</div>
                </div>
            )
        }
    ]

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                // padding: "10px 10px",
                color: "#4D4D4D",
                fontSize: "13px",
            },
        },
        head: {
            style: {
                // borderRadius: "6px 6px 0px 0px",
                // borderTop: "0.824px solid #E9E9E9",
                background: "#FFF",
                // fontSize: "15px",
            },
        },
        rows: {
            style: {
                // borderRadius: "6px 6px 0px 0px",
                // borderTop: "0.824px solid #E9E9E9",
                background: "#FFF",
                color: "#565656",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
            },
        },
        // pagination: {
        //    style: {
        //       boxShadow: "10px 5px 5px #ddd",
        //       marginBottom: "5px",
        //    },
        // },
        table: {
            style: {
                overflow: "visible",
                minWidth: "1200px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto", // table scroll on x axis
            },
        },
    };

    return (
        <div className='p-10 rounded-md mx-[1rem] my-[2rem] bg-white'>
            <div className="flex items-center justify-between">
                <button
                    className="flex items-center justify-center px-3 py-1 mb-4 border rounded-md border-primary text-primary cursor-pointer"
                    onClick={() => navigate("/tournaments")}
                >
                    <Icon
                        icon="material-symbols:arrow-back-ios"
                        className="text-[1rem]"
                    />
                    Back
                </button>
                <NavLink to={"staffRecords"}>
                    <p className="text-primary border-b border-b-primary">STAFF RECORDS.</p>
                </NavLink>
            </div>
            <div className="flex">
                <div className="p-8 border border-[#767a81] rounded-lg">
                    <h3 className="font-semibold text-xl pb-6">Basic Info</h3>
                    <div className="flex flex-col gap-[1.5rem]">
                        <div className="flex items-center gap-[2rem]">
                            <input
                                type="text"
                                placeholder="Name"
                                className="w-[300px] max-lg:w-[250px] text-base font-[500] py-[0.6rem] px-[1rem] bg-gray-50 placeholder:text-[#7F7F7F] rounded-md outline-none"
                                value={ownerName}
                            />
                            <input
                                type="number"
                                placeholder="Mobile Number"
                                className="w-[300px] max-lg:w-[250px] text-base font-[500] py-[0.6rem] px-[1rem] bg-gray-50 placeholder:text-[#7F7F7F] rounded-md outline-none"
                                value={mobileNumber}
                            />
                        </div>
                        <div className="flex items-center gap-[2rem]">
                            <input
                                type="text"
                                placeholder="Email"
                                className="w-[300px] max-lg:w-[250px] cursor-not-allowed text-base font-[500] py-[0.6rem] px-[1rem] bg-gray-50 placeholder:text-[#7F7F7F] rounded-md outline-none"
                                value={email}
                                disabled={true}
                            />
                            {isStaff === false &&
                                <input
                                    type="text"
                                    placeholder="Club Name"
                                    className="w-[300px] max-lg:w-[250px] cursor-not-allowed text-base font-[500] py-[0.6rem] px-[1rem] bg-gray-50 placeholder:text-[#7F7F7F] rounded-md outline-none"
                                    value={clubName}
                                    disabled={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-xl font-semibold pt-[2rem]">Clubs Details</h3>
            {isLoading ? <div className="flex justify-center items-center">
                <RotatingLines
                    visible={true}
                    height="80"
                    width="80"
                    color="gray"
                    strokeColor="#FB6108"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
                :
                <DataTable
                    columns={columns}
                    data={clubsData}
                    customStyles={customStyles}
                    pagination
                    paginationPerPage={10}
                />
            }
        </div>
    )
}
export default Profile;