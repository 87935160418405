import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PiGreaterThan } from "react-icons/pi";
import Select from 'react-select';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { PulseLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import { useQuery } from 'react-query';

export default function CreateVenue() {

    const navigate = useNavigate();
    const { tournamentId } = useParams();
    const { getAccessToken, userRole } = useAuth();

    const [venue, setVenue] = useState({ value: "", label: "" });
    const [court, setCourt] = useState({ value: "", label: "" });
    const [arena, setArena] = useState({ value: "", label: "" });
    const [venuesOptions, setVenuesOptions] = useState([]);
    const [areanOptions, setArenaOptions] = useState([]);
    const [courtOptions, setCourtOptions] = useState([]);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchDropDownsData = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchDropDownDataForAddingVenues}?club=${venue?.value === undefined ? "" : venue?.value}&arena=${arena?.value === undefined ? "" : arena?.value}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            console.log("Drop Down Data", result?.response)
            setVenuesOptions(result?.response?.clubs?.map((item) => ({
                value: item?.clubId,
                label: item?.clubName
            })));
            setArenaOptions(result?.response?.arenas?.map((item) => ({
                value: item?.arenaId,
                label: item?.arenaName
            })));
            setCourtOptions(result?.response?.court?.map((item) => ({
                value: item?.courtId,
                label: item?.courtName
            })))
            return result?.response
        } catch (error) {
            console.log("Error while fetching drop down data for adding venues", error)
        }
    }

    const { data: dropdownsData, isLoading, refetch } = useQuery("dropDownVenues", fetchDropDownsData)

    useEffect(() => {
        refetch();
    }, [venue, arena, refetch])

    useEffect(() => {
        setArena({ value: "", label: "" })
    }, [venue])

    const handleSaveVenue = async (e) => {
        e.preventDefault();
        if (!venue?.value) {
            setShowError(true);
            return;
        }

        const venueDetails = {
            venueClubId: venue?.value,
            arenaId: arena?.value,
            boardId: court?.value
        }

        try {
            const token = await getAccessToken();
            setLoading(true);
            const response = await fetch(`${APIurls.createVenueForTournament}/${tournamentId}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(venueDetails)
            })
            const result = await response.json();
            if (result.code > 201) {
                toast.error(result?.message)
                throw new Error(result?.message)
            }
            console.log("Venue Created Successfully", result?.response)
            setShowError(false);
            toast.success("Venue Created Successfully");
            setTimeout(() => { navigate(`/tournaments/tournamentDetails/${tournamentId}`) }, 2000)
        } catch (error) {
            console.log("Error while creating venue")
        }
        setLoading(false);
        setVenue({ value: "", label: "" });
        setArena({ value: "", label: "" });
        setCourt({ value: "", label: "" });
    };

    const styles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#F6F7FB",
            boxShadow: state?.isFocused ? "none" : provided.boxShadow,
            borderColor: state?.isFocused ? "initial" : provided.borderColor, // Resets the border color on focus
            "&:hover": {
                borderColor: state?.isFocused ? "initial" : provided.borderColor, // Ensures no border color change on hover
            },
        }),
    };

    return (
        <>
            <form onSubmit={handleSaveVenue}>
                <div className='flex items-center justify-between p-2 bg-white rounded-md mt-5'>
                    <div className='flex items-center gap-1'>
                        <p className='text-[#808080] font-semibold text-base cursor-pointer'
                            onClick={() => navigate("/tournaments")}
                        >
                            Tournament
                        </p>
                        <PiGreaterThan className='text-[#808080]' />
                        <p className='text-[#808080] font-semibold text-base cursor-pointer'
                            onClick={() => navigate(`/tournaments/tournamentDetails/${tournamentId}`)}
                        >
                            Overview of the Tournament
                        </p>
                        <PiGreaterThan className='text-[#808080]' />
                        <p className='text-xl font-semibold'>Venue</p>
                    </div>
                    <div className='flex justify-end'>
                        {loading ? <PulseLoader color='orange' size={8} />
                            :
                            <button type='submit' className='flex items-center bg-primary text-white px-4 py-2 rounded-md gap-2 text-base'>
                                <p>Save</p>
                                <PiGreaterThan />
                            </button>
                        }
                    </div>
                </div>
                <div className='bg-white my-5 rounded-md'>
                    <div>
                        <div className='flex items-center justify-between px-[2rem] pt-[0.8rem]'>
                            <p className='text-xl font-semibold'>Venue</p>
                        </div>
                        <div className='min-h-screen flex gap-[3rem] px-[2rem] py-[1rem]'>
                            <div className='flex flex-col gap-[0.5rem]'>
                                <div className=''>
                                    <Select
                                        className='w-[250px] px-4 py-2 text-gray-700'
                                        placeholder='Select Venue'
                                        value={venue?.value ? venue : ""}
                                        options={venuesOptions}
                                        styles={styles}
                                        onChange={(selectedOption) => setVenue(selectedOption)}
                                    />
                                    {!venue?.value && showError && (
                                        <p className='text-xs px-4 text-red-500'>*This field is required</p>
                                    )}
                                </div>
                                <div className=''>
                                    <Select
                                        className='w-[250px] px-4 py-2 text-gray-700'
                                        placeholder='Select Court'
                                        value={court?.value ? court : ""}
                                        options={courtOptions}
                                        styles={styles}
                                        onChange={(selectedOption) => setCourt(selectedOption)}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-[0.5rem]'>
                                <Select
                                    className='w-[250px] px-4 py-2 text-gray-700'
                                    placeholder='Select Arena'
                                    value={arena?.value ? arena : ""}
                                    options={areanOptions}
                                    styles={styles}
                                    onChange={(selectedOption) => setArena(selectedOption)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer />
        </>
    );
}
