import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useAuth } from "../store/AuthContext";
import { APIurls } from "../api/apiConstant";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import moment from "moment";
import ActivityRemarkModel from "../components/custom/ActivityRemarkModel";
import { RotatingLines } from "react-loader-spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";

export default function StaffRecords() {

  const [clubOptions, setClubOptions] = useState([]);
  const [club, setClub] = useState({ value: "", label: "" });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activityModel, setActivityModal] = useState(false);
  const [remark, setRemark] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [date, setDate] = useState("");

  const { getAccessToken, userRole } = useAuth();
  const navigate = useNavigate();

  const fetchClubs = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllClubs}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message);
      }
      const result = await response.json();
      console.log("Clubs Data--->", result?.response?.clubs);
      if (userRole?.role?.staff?.type) {
        const obj = {
          value:
            result?.response?.clubs.length > 0
              ? result?.response?.clubs[0]?.id
              : "",
          label:
            result?.response?.clubs.length > 0
              ? result?.response?.clubs[0]?.clubName
              : "",
        };
        setClub(obj);
      }
      setClubOptions(
        result?.response?.clubs?.map((item) => ({
          value: item?.id,
          label: item?.clubName,
        }))
      );
    } catch (error) {
      console.log("Error in fetching clubs", error);
    }
  };

  useEffect(() => {
    fetchClubs();
  }, []);

  const styles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F6F7FB",
      boxShadow: state?.isFocused ? "none" : provided.boxShadow,
      borderColor: state?.isFocused ? "initial" : provided.borderColor, // Resets the border color on focus
      "&:hover": {
        borderColor: state?.isFocused ? "initial" : provided.borderColor, // Ensures no border color change on hover
      },
    }),
  };
  
  const handleShowMoreModal = (message) => {
    setActivityModal(true);
    setRemark(message);
  };
  const columns = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      width: "80px",
      center: true,
    },
    {
      name: "Club Name",
      selector: (row) => <div>{row?.clubName}</div>,
      center: true,
    },
    {
      name: "Tournament",
      selector: (row) => <div>{row?.tournamentID}</div>,
      center: true,
    },
    {
      name: "Activity",
      selector: (row) => (
        <div>
          {row?.activityRemark.length > 20 ? (
            <div className="flex items-center gap-1 max-lg:flex max-lg:flex-wrap">
              <p className="max-lg:text-[18px]">
                {row?.activityRemark?.substring(0, 20)} . . .
              </p>
              <p
                className="text-[0.7rem] font-medium hover:text-primary hover:cursor-pointer"
                onClick={() => handleShowMoreModal(row?.activityRemark)}
              >
                Show More
              </p>
            </div>
          ) : (
            <p className="font-medium text-base max-lg:text-[18px]">
              {row?.activityRemark}
            </p>
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Date",
      selector: (row) => (
        <div className="flex flex-col gap-1">
          <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
          <div>{moment(row?.createdAt).format("hh:mm:ss A")}</div>
        </div>
      ),
      center: true,
    },
    {
      name: "Staff Details",
      selector: (row) => (
        <div>
          <div>{row?.staffName}</div>
          <div>{row?.staffEmail}</div>
          <div>{row?.staffDesignation}</div>
          <div>{row?.staffMobile}</div>
        </div>
      ),
      center: true,
    },
  ];
  const columns2 = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      width: "80px",
      center: true,
    },
    {
      name: "Tournament",
      selector: (row) => <div>{row?.tournamentID}</div>,
      center: true,
    },
    {
      name: "Tournament Format",
      selector: (row) => <div>{row?.tournamentFormat}</div>,
      center: true,
    },
    {
      name: "Activity",
      selector: (row) => (
        <div>
          {row?.activityRemark.length > 20 ? (
            <div className="flex items-center gap-1 max-lg:flex max-lg:flex-wrap">
              <p className="max-lg:text-[18px]">
                {row?.activityRemark?.substring(0, 20)}...
              </p>
              <p
                className="text-[0.7rem] font-medium hover:text-primary hover:cursor-pointer"
                onClick={() => handleShowMoreModal(row?.activityRemark)}
              >
                Show More
              </p>
            </div>
          ) : (
            <p className="font-medium text-base max-lg:text-[18px]">
              {row?.activityRemark}
            </p>
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Date",
      selector: (row) => (
        <div className="flex flex-col gap-1">
          <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
          <div>{moment(row?.createdAt).format("hh:mm:ss A")}</div>
        </div>
      ),
      center: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },
    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const getClubsStaffRecords = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.fetchStaffRecords}?page=${page}&limit=${limit}&OwnerClubID=${club?.value ? club?.value : ""}&startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message);
      }
      const result = await response.json();
      setIsLoading(false);
      console.log("Records Data--->", result?.response);
      setRecords(result?.response?.records);
      setTotalRecords(result?.response?.totalRecords);
      if (result?.response?.totalRecords === 0) {
        setPage(0);
      } else {
        const last = Math.ceil(totalRecords / limit);
        if (page > last || page === 0) {
          setPage(1);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error in fetching clubs", error?.message);
    }
  };

  useEffect(() => {
    getClubsStaffRecords();
  }, [page, limit, club, startDate, endDate, date]);

  const onChangePage = (direction) => {
    if (direction === "left") {
      if (page === 1) {
        return;
      }
      setPage(page - 1);
    }
    if (direction === "right") {
      const last = Math.ceil(totalRecords / limit);
      if (page === last) {
        return;
      }
      setPage(page + 1);
    }
  };
  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  const handleDateChange = (selectedDates) => {
    setDate(selectedDates);
    console.log(selectedDates[0]);
    console.log(selectedDates[1])
    if (selectedDates.length === 1) {
      setStartDate(new Date(selectedDates[0]).toUTCString());
    }
    if (selectedDates.length === 2) {
      setEndDate(new Date(selectedDates[1]).toUTCString());
    }
  };

  const handleReset = () => {
    if ((club?.value !== "") || (startDate !== "") || (endDate !== "") || (date !== "")) {
      setClub({ value: "", label: "" });
      setStartDate("");
      setEndDate("");
      setDate("");
      getClubsStaffRecords();
    }
  }

  return (
    <div className="p-10 rounded-md mx-[1rem] my-[2rem] bg-white">
      <div className="flex items-center justify-between pb-[1rem]">
        <button
          className="flex items-center justify-center px-3 py-1 border rounded-md border-primary text-primary cursor-pointer"
          onClick={() => navigate("/tournaments/profile")}
        >
          <Icon
            icon="material-symbols:arrow-back-ios"
            className="text-[1rem]"
          />
          Back
        </button>
        <div className="flex items-center gap-2">
          <div className="flex items-center border rounded-md">
            <Flatpickr
              value={date}
              className="w-full px-4 py-2 text-gray-700 bg-white focus:outline-none"
              placeholder="Select From - To Dates"
              options={dateOptions}
              onChange={handleDateChange}
            />
            <Icon className="mr-2 text-gray-400" icon="uiw:date" />
          </div>
          <Select
            placeholder="Select Club"
            styles={styles}
            className={`w-[200px]  ${userRole?.role?.staff?.type ? "cursor-not-allowed" : ""
              }`}
            options={clubOptions}
            value={club?.value ? club : ""}
            onChange={(selectedOption) => setClub(selectedOption)}
            isDisabled={userRole?.role?.staff?.type}
          ></Select>
          <button
            className="bg-primary rounded-md text-white px-3 py-2 text-center"
            type="button"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      </div>
      {isLoading && (
        <>
          <div className="flex justify-center items-center">
            <RotatingLines
              visible={true}
              height="35"
              width="35"
              color="gray"
              strokeColor="#FB6108"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      )}
      {!isLoading && (userRole?.role?.clubOwner || userRole?.role?.coOwner) && (
        <>
          <DataTable
            data={records}
            columns={columns}
            customStyles={customStyles}
          // pagination
          // paginationPerPage={10}
          />
        </>
      )}
      {!isLoading && userRole?.role?.staff?.type && (
        <>
          <DataTable
            data={records}
            columns={columns2}
            customStyles={customStyles}
          // pagination
          // paginationPerPage={10}
          />
        </>
      )}
      {/* <CustomPagination /> */}
      <div className="flex justify-center items-center mt-4 gap-5">
        <div className="flex justify-between items-center gap-2">
          <FaAngleDoubleLeft
            size={"1rem"}
            className={`${page <= 1
              ? "cursor-not-allowed text-blue-gray-700"
              : " cursor-pointer active:size-6 active:text-blue-gray-700"
              } mr-2`}
            onClick={() => onChangePage("left")}
          />
          <div className="flex gap-2">
            <span>{page}</span> / <span>{Math.ceil(totalRecords / limit)}</span>
          </div>
          <FaAngleDoubleRight
            size={"1rem"}
            className={`${page === Math.ceil(totalRecords / limit)
              ? "cursor-not-allowed text-blue-gray-700"
              : " cursor-pointer active:size-6 active:text-blue-gray-700"
              } ml-2`}
            onClick={() => onChangePage("right")}
          />
        </div>
        <div className="flex gap-2">
          <p>Per Page :</p>
          <select onChange={(e) => setLimit(+e.target.value)}>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={30}>30</option>
          </select>
        </div>
      </div>
      {activityModel && (
        <ActivityRemarkModel
          remark={remark}
          setActivityModal={setActivityModal}
        />
      )}
    </div>
  );
}
