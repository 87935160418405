import { createBrowserRouter, Navigate } from "react-router-dom";
import ForgotPassword from "../pages/login/ForgotPassword";
import ClubOwnerLogin from "../pages/login/ClubOwnerLogin";
import ClubOwnerLayout from "../layouts/ClubOwnerLayout";
import Tournament from "../components/tournamentModules/Tournament";
import CreateTournament from "../components/tournamentModules/CreateTournament";
import Ongoing from "../components/tournamentModules/Ongoing";
import Upcoming from "../components/tournamentModules/Upcoming";
import Completed from "../components/tournamentModules/Completed";
import Archived from "../components/tournamentModules/Archived";
import TournamentDetails from "../components/tournamentModules/TournamentDetails/TournamentDetails";
import OverviewOfTournament from "../components/tournamentModules/TournamentDetails/OverViewOfTournament";
import CreationOfParticipants from "../components/tournamentModules/TournamentDetails/CreationOfPartcipants";
import GameFixtures from "../components/tournamentModules/TournamentDetails/GameFixtures";
import Stats from "../components/tournamentModules/TournamentDetails/Stats";
import Rewards from "../components/tournamentModules/TournamentDetails/Rewards";
import EditOverViewTournament from "../components/tournamentModules/TournamentDetails/EditOverViewOfTournament";
import All from "../components/tournamentModules/All";
import ViewTeamParticipants from "../components/tournamentModules/TournamentDetails/ViewTeamParticipants";
import CreateVenue from "../components/tournamentModules/TournamentDetails/CreateVenue";
import AddOrganizerOrSponsor from "../components/tournamentModules/TournamentDetails/AddOrganizerOrSponsor";
import EditOrganizerOrSponsor from "../components/tournamentModules/TournamentDetails/EditOrganizerOrSponsor";
import { useAuth } from "../store/AuthContext";
import Profile from "../layouts/Profile";
import StaffRecords from "../layouts/StaffRecords";

const PrivateRoute = ({ component: Component }) => {

    const { currentUser, userRole } = useAuth()

    return (
        <div>
            {((currentUser && userRole?.role?.clubOwner) || (userRole?.role?.staff?.type)) ? <Component /> : <Navigate to={"/"} replace />}
        </div>
    )
}

const PublicRoute = ({ component: Component }) => {

    const { currentUser, userRole } = useAuth()

    return (
        <div>
            {((currentUser && userRole?.role?.clubOwner) || (userRole?.role?.staff?.type)) ? <Navigate to={"/tournaments"} /> : <Component />}
        </div>
    )
}

const router = createBrowserRouter([
    {
        path: "",
        element: <PublicRoute component={ClubOwnerLogin} />,
    },
    {
        path: "/forgotpassword",
        element: <ForgotPassword />
    },
    {
        path: "tournaments",
        element: <PrivateRoute component={ClubOwnerLayout} />,
        children: [
            {
                path: "",
                element: <PrivateRoute component={Tournament} />,
                children: [
                    {
                        path: "",
                        element: <PrivateRoute component={All} />
                    },
                    {
                        path: "ongoing",
                        element: <PrivateRoute component={Ongoing} />
                    },
                    {
                        path: "upcoming",
                        element: <PrivateRoute component={Upcoming} />
                    },
                    {
                        path: "completed",
                        element: <PrivateRoute component={Completed} />
                    },
                    {
                        path: "archived",
                        element: <PrivateRoute component={Archived} />
                    }
                ]
            },
            {
                path: "tournamentDetails/:tournamentId",
                element: <PrivateRoute component={TournamentDetails} />,
                children: [
                    {
                        path: "",
                        element: <PrivateRoute component={OverviewOfTournament} />
                    },
                    {
                        path: "team_creation",
                        element: <PrivateRoute component={CreationOfParticipants} />
                    },
                    {
                        path: "game_fixtures",
                        element: <PrivateRoute component={GameFixtures} />
                    },
                    {
                        path: "stats",
                        element: <PrivateRoute component={Stats} />
                    },
                    {
                        path: "rewards",
                        element: <PrivateRoute component={Rewards} />
                    }
                ]
            },
            {
                path: "create",
                element: <PrivateRoute component={CreateTournament} />
            },
            {
                path: "tournamentDetails/:tournamentId/editOverViewTournament",
                element: <PrivateRoute component={EditOverViewTournament} />
            },
            {
                path: "tournamentDetails/:tournamentId/viewParticipants/:teamId",
                element: <PrivateRoute component={ViewTeamParticipants} />
            },
            {
                path: "tournamentDetails/:tournamentId/createVenue",
                element: <PrivateRoute component={CreateVenue} />
            },
            {
                path: "tournamentDetails/:tournamentId/addOrganizer",
                element: <PrivateRoute component={AddOrganizerOrSponsor} />
            },
            {
                path: "tournamentDetails/:tournamentId/editOrganizerDetails/:sponsorID",
                element: <PrivateRoute component={EditOrganizerOrSponsor} />
            },
            {
                path: "profile",
                element: <PrivateRoute component={Profile} />
            },
            {
                path: "profile/staffRecords",
                element: <PrivateRoute component={StaffRecords} />
            }
        ]
    },
])
export default router;