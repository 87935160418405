import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { APIurls } from "../api/apiConstant";
import { Navigate } from "react-router-dom";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {

  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState();

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function logout() {
    return signOut(auth)
      .then(() => {
        setCurrentUser(null); // Clear current user state
        setUserRole(null); // Clear user role state
        Navigate("/"); // Redirect to home page after logout
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  }

  // function resetPassword(email) {
  //     return sendPasswordResetEmail(auth, email);
  // }

  // function updateEmail(email) {
  //     return currentUser.updateEmail(email)
  // }

  // function updatePassword(password) {
  //     return currentUser.updatePassword(password)
  // }

  function getAccessToken() {
    return currentUser?.getIdToken(true);
  }

  function saveUserRole(value) {
    setUserRole(value);
  }

  const getUserRoleFunc = async (user) => {
    try {
      const result = await fetch(APIurls.getRoles, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      const resultJson = await result.json();
      console.log(resultJson);
      if (!result.ok) {
        throw new Error("Failed to login");
      }
      setUserRole(resultJson?.response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      await getUserRoleFunc(user);
      const token = await user?.getIdToken(true);
      setLoading(false);
    });
    console.log(unsubscribe);
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    // resetPassword,
    // updateEmail,
    // updatePassword,
    getAccessToken,
    saveUserRole,
    userRole,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
