import React, { useState, useEffect } from "react";
import { PiGreaterThan } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";
import uploadImage from '../../../assets/uploadImg.svg'
import { MdCloudUpload } from "react-icons/md";
import Select from 'react-select'
import { RiDeleteBinFill } from "react-icons/ri";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { PulseLoader } from 'react-spinners';
import { RotatingLines } from "react-loader-spinner";

export default function EditOverViewTournament() {

    const navigate = useNavigate();

    const [showError, setShowError] = useState(false);
    const [mainCategorySport, setMainCategorySport] = useState({ value: "", label: "" });
    const [subCategorySport, setSubCategorySport] = useState({ value: "", label: "" });
    const [selectType, setSelectType] = useState({ value: "", label: "" });
    const [formatType, setFormatType] = useState({ value: "", label: "" });
    const [fixingType, setFixingType] = useState({ value: "", label: "" });
    const [handleStartDate, setHandleStartDate] = useState("");
    const [handleEndDate, setHandleEndDate] = useState("");
    const [aboutTournament, setAboutTournament] = useState("");
    const [participants, setParticipants] = useState("");
    const [selectSport, setSelectSport] = useState({ value: "", label: "" });
    const [tournamentName, setTournamentName] = useState("");
    const [selectedFile, setSelectedFile] = useState([]);
    const [previewImage, setPreviewImage] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [mainCategoryOptions, setMainCategoryOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [tournamentStatus, setTournamentStatus] = useState("");
    const [mainCategoryData, setMainCategoryData] = useState([])
    const [selectSportOptions, setSelectSportOptions] = useState([])
    const [score, setScore] = useState("")
    const [scoreType, setScoreType] = useState({ value: "", label: "" })
    const [scoreTypeOptions, setScoreTypeOptions] = useState([])
    const [scoreError, setScoreError] = useState(false)
    const [savingScore, setSavingScore] = useState(false);
    const [isSubSportCategory, setIsSubSportCategory] = useState(false);
    const [scoreChange, setScoreChange] = useState(false);

    const { tournamentId } = useParams();
    const { getAccessToken } = useAuth();

    const fetchTournamentDetails = async () => {
        try {
            setIsLoading(true)
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchOverViewOfTournament}/${tournamentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            console.log("Existing Over View Details--->", result?.response?.tournamentDetails)
            setScoreTypeOptions(result?.response?.tournamentDetails?.scoreTypeNames?.map((item) => {
                return {
                    value: item,
                    label: item
                }
            }))
            setScoreType({
                value: result?.response?.tournamentDetails?.scoreType,
                label: result?.response?.tournamentDetails?.scoreType
            })
            setScore(result?.response?.tournamentDetails?.noOfMatchSets)
            setTournamentStatus(result?.response?.tournamentDetails?.status)
            setMainCategorySport({
                value: result?.response?.tournamentDetails?.mainCategoryID,
                label: result?.response?.tournamentDetails?.mainCategoryName
            });
            setSubCategorySport({
                value: result?.response?.tournamentDetails?.subCategoryID,
                label: result?.response?.tournamentDetails?.subCategoryName
            });
            setIsSubSportCategory(true);
            setSelectType({
                value: result?.response?.tournamentDetails?.tournamentType,
                label: result?.response?.tournamentDetails?.tournamentType
            })
            setParticipants(result?.response?.tournamentDetails?.participants)
            setFormatType({
                value: result?.response?.tournamentDetails?.format,
                label: result?.response?.tournamentDetails?.format
            })
            setSelectSport({
                value: result?.response?.tournamentDetails?.sportID,
                label: result?.response?.tournamentDetails?.sportName
            })
            setFixingType({
                value: result?.response?.tournamentDetails?.fixingType,
                label: result?.response?.tournamentDetails?.fixingType
            })
            setTournamentName(result?.response?.tournamentDetails?.tournamentName)
            const obj1 = new Date(result?.response?.tournamentDetails?.startDate);
            const convertedStartDate = obj1?.getDate() < 10 ? `0${obj1?.getDate()}` : obj1?.getDate();
            const convertedStartMonth = obj1?.getMonth() + 1 < 10 ? `0${obj1?.getMonth() + 1}` : obj1?.getMonth() + 1;
            setHandleStartDate(result?.response?.tournamentDetails?.startDate === null ? "" : `${obj1?.getFullYear()}-${convertedStartMonth}-${convertedStartDate}`)

            const obj2 = new Date(result?.response?.tournamentDetails?.endDate);
            const convertedEndDate = obj2?.getDate() < 10 ? `0${obj2?.getDate()}` : obj2?.getDate();
            const convertedEndMonth = obj2?.getMonth() + 1 < 10 ? `0${obj2?.getMonth() + 1}` : obj2?.getMonth() + 1;
            setHandleEndDate(result?.response?.tournamentDetails?.endDate === null ? "" : `${obj2?.getFullYear()}-${convertedEndMonth}-${convertedEndDate}`)

            setAboutTournament(result?.response?.tournamentDetails?.description)
            setPreviewImage(result?.response?.tournamentDetails?.BannerImg)
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    };

    const fetchMainCategories = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchCategories}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            console.log("Main Categories--->", result?.response?.categories)
            setMainCategoryOptions(result?.response?.categories?.map((item) => {
                return ({
                    value: item?._id,
                    label: item?.categoryName
                })
            }))
            setMainCategoryData(result?.response?.categories)
        } catch (error) {
            console.log("Error while fetching main categories", error)
        }
    }

    const fetchAllSports = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAllTournamentSports}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            console.log("Sports--->", result?.response?.tournamentSports)
            setSelectSportOptions(result?.response?.tournamentSports?.map((item) => {
                return {
                    value: item?._id?.toString(),
                    label: item?.name
                }
            }))
        } catch (error) {
            console.log("Error while fetching Sports", error.message)
        }
    }

    useEffect(() => {
        (async () => {
            fetchMainCategories();
            await fetchTournamentDetails();
            fetchAllSports();
        })()
    }, [])

    const updateTournamentDetails = async (formData) => {
        const token = await getAccessToken();
        try {
            const response = await fetch(
                `${APIurls.updateTournamentDetails}/${tournamentId}`,
                {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                }
            );
            const result = await response.json();
            console.log("tournament update response ", result);
            if (result.code > 201) {
                toast.error(result?.message);
                throw new Error(result?.message);
            }
            console.log("Tournament Updated Successfully", result?.response)
            toast.success("Tournament updated Successfully");
            return result?.response;
        }
        catch (error) {
            console.log(error)
        }
    };
    const handleSave = async (e) => {
        e.preventDefault();
        if (
            !mainCategorySport?.value ||
            !selectType?.value ||
            !formatType?.value ||
            !fixingType?.value ||
            !handleStartDate ||
            !aboutTournament ||
            !subCategorySport?.value ||
            !participants ||
            !selectSport?.value ||
            !tournamentName ||
            !handleEndDate ||
            !selectedFile
        ) {
            setShowError(true);
            return;
        } else {
            setShowError(false);
        }
        try {
            let gameType = selectType?.label === "Team Match" ? "team" : "individual";

            let formatName = "";
            if (formatType?.value === "Knockout") {
                formatName = "knockout";
            }
            if (formatType?.value === "Double Elimination Bracket") {
                formatName = "double_elimination_bracket";
            }
            if (formatType?.value === "Round Robbin") {
                formatName = "round_robbin";
            }
            const formData = new FormData();
            formData.append("name", tournamentName);
            formData.append("mainCategoryID", mainCategorySport?.value);
            formData.append("subCategoryID", subCategorySport?.value);
            formData.append("gameType", gameType);
            formData.append("fixingType", fixingType?.value);
            formData.append("participants", participants);
            formData.append("formatType", formatName);
            formData.append("sportID", selectSport?.value);
            formData.append("startDate", handleStartDate);
            formData.append("endDate", handleEndDate);
            formData.append("description", aboutTournament);
            const oldBannerImgUrls = [];
            const newSelectedFiles = [];
            for (let img of previewImage) {
                if (typeof img === "string") {
                    oldBannerImgUrls.push(img);
                } else if (img instanceof File) {
                    newSelectedFiles.push(img);
                }
            }
            formData.append("oldBannerImgUrls", JSON.stringify(oldBannerImgUrls));
            console.log('oldBannerImgUrls : ', JSON.stringify(oldBannerImgUrls))
            for (let file of newSelectedFiles) {
                formData.append("BannerImg", file);
            }
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            setIsSaving(true);
            const updatedResponse = await updateTournamentDetails(formData);
            setIsSaving(false);
            console.log("Successfully Updated The Tournament", updatedResponse);
            setTimeout(
                () => navigate(`/tournaments/tournamentDetails/${tournamentId}`),
                2500
            );
        } catch (error) {
            setIsSaving(false);
            console.log("error in saving tournament details :", error?.message);
        }
    };

    const handleFileInput = (event) => {
        let file = event.target.files[0];
        setSelectedFile((previous) => ([...previous, file]))
        setPreviewImage((preview) => ([...preview, file]));
    };

    const triggerFileInput = () => {
        document.getElementById("fileInput").click();
    };

    const handleImageDelete = (i) => {
        const filteredImageData = previewImage?.filter((item, index) => index !== i)
        setPreviewImage(filteredImageData)
    }
    const loadImageUrl = (image) => {
        // Check if the provided value is a URL or a File
        if (typeof image === "string") {
            // If it's a URL, set the image source directly
            return image;
        } else if (image instanceof File) {
            // If it's a File, create an object URL and set it as the image source
            const objectUrl = URL.createObjectURL(image);
            return objectUrl;
            // Clean up the object URL when the component unmounts
            //   return () => URL.revokeObjectURL(objectUrl);
        }
    };

    useEffect(() => {
        if (mainCategorySport) {
            const mainCat = mainCategoryData?.find((div) => div?._id === mainCategorySport?.value);
            setSubCategoryOptions(mainCat?.subCategoriesDetails?.map((div) => ({
                value: div?._id,
                label: div?.subCategoryName
            })))
        }
        if (isSubSportCategory) {
            setIsSubSportCategory(false);
        }
    }, [mainCategorySport, mainCategoryData]);

    useEffect(() => {
        if (!isSubSportCategory) {
            setSubCategorySport({ value: "", label: "" });
        }
    }, [mainCategorySport])

    const handleUpdateScore = async () => {
        if (score === "") {
            setScoreError(true)
            return;
        } else if (score < 0) {
            setScoreError(true);
            toast.error('Enter Valid Positive Set Number');
            return;
        }

        const body = {
            scoreType: scoreType?.value,
            noOfMatchSets: scoreType?.value === "Top Score" ? 1 : scoreType?.value === "Cricket" ? 1 : +score
        }
        try {
            const token = await getAccessToken();
            setSavingScore(true)
            const response = await fetch(`${APIurls.updateScoreType}/${tournamentId}`, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(body)
            })
            const result = await response.json();
            if (!response.ok) {
                toast.error(result.message);
                throw new Error(result?.message)
            }
            console.log("Score Updated Successfully", result)
            toast.success("Score Type Successfully Updated")
        } catch (error) {
            console.log("Error while Updating Score", error)
        }
        setSavingScore(false);
        setScoreChange(false);
    }

    const styles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#F6F7FB",
            boxShadow: state?.isFocused ? "none" : provided.boxShadow,
            borderColor: state?.isFocused ? "initial" : provided.borderColor, // Resets the border color on focus
            "&:hover": {
                borderColor: state?.isFocused ? "initial" : provided.borderColor, // Ensures no border color change on hover
            },
        }),
    };

    const selectTypeOptions = [
        { value: 'Team Match', label: 'Team Match' },
        { value: 'Individual', label: 'Individual' }
    ]

    const selectFormatOptions = [
        { value: 'Knockout', label: 'Knockout' },
        { value: "Double Elimination Bracket", label: "Double-Elimination Bracket" },
        { value: 'Round Robbin', label: "Round Robbin" }
    ]

    const fixingTypeOptions = [
        { value: "random", label: "Random" },
        { value: "manual", label: "Manual" },
        { value: "sequential", label: "Sequential" },
        { value: "top_vs_bottom", label: 'Top vs Bottom' },
        { value: 'seeding', label: "Seeding" }
    ]

    return (
        <>
            <div className="flex justify-between p-3 my-[1rem] bg-white">
                <div className="flex items-center">
                    <p
                        className="text-[#808080] font-semibold text-base cursor-pointer"
                        onClick={() => navigate("/tournaments")}
                    >
                        Tournament
                    </p>
                    <PiGreaterThan className="text-[#808080]" />
                    <p
                        className="text-[#808080] font-semibold text-base cursor-pointer"
                        onClick={() =>
                            navigate(`/tournaments/tournamentDetails/${tournamentId}`)
                        }
                    >
                        Overview of the Tournament
                    </p>
                    <PiGreaterThan className="text-[#808080]" />
                    <p className="text-xl font-semibold">Tournament Details</p>
                </div>
            </div>
            <div className="bg-white">
                {isLoading ?
                    <div className="flex justify-center items-center min-h-screen">
                        <RotatingLines
                            visible={true}
                            height="100"
                            width="100"
                            color="gray"
                            strokeColor="#FB6108"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                    :
                    <div className="pt-[1rem]">
                        <label className="px-10 text-[17px] font-semibold">Edit Score Type:</label>
                        <div className="flex items-center gap-[1.5rem] mb-3 mx-4 px-6 pt-[0.5rem] pb-[1rem] border-b-4">
                            <Select
                                className="w-[250px] px-4 py-2 rounded-md"
                                placeholder="Select Score Type"
                                value={scoreType?.value ? scoreType : ""}
                                styles={styles}
                                options={scoreTypeOptions}
                                onChange={(selectedOption) => { setScoreType(selectedOption); setScoreChange(true) }}
                            >
                            </Select>
                            <div className="">
                                <input
                                    type="number"
                                    className={`${scoreType?.value === "Top Score" ? "cursor-not-allowed" : scoreType?.value === "Cricket" ? "cursor-not-allowed" : ""} w-[200px] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none`}
                                    placeholder={`${scoreType?.value === "Top Score" ? "Enter Top Score" : scoreType?.value === "Best Of" ? "Enter Best of" : scoreType?.value === "Race Of" ? "Enter Race of" : "Others"}`}
                                    value={scoreType?.value === "Top Score" ? 1 : scoreType?.value === "Cricket" ? 1 : score}
                                    onChange={(e) => setScore(+e.target.value)}
                                    disabled={scoreType?.value === "Top Score" || scoreType?.value === "Cricket"}
                                    min="1"
                                    max="20"
                                />
                                {!score && scoreError && (
                                    <p className="text-xs text-red-500">*This field is required</p>
                                )}
                            </div>
                            <div className={`${scoreChange ? "bg-green-600" : "bg-primary"} flex items-center justify-center text-white rounded-md px-3 py-2 max-lg:px-2`}>
                                {savingScore ?
                                    <PulseLoader color="white" size={8} />
                                    :
                                    <button
                                        className="flex gap-1 items-center"
                                        onClick={handleUpdateScore}
                                    >
                                        {scoreChange && "*"}Save <PiGreaterThan />
                                    </button>
                                }
                            </div>
                        </div>
                        <form onSubmit={handleSave}>
                            <div className="flex justify-end pr-[2rem]">
                                {isSaving ?
                                    <PulseLoader color="orange" size={8} />
                                    :
                                    <button
                                        className="flex items-center justify-center bg-primary text-white rounded-md cursor-pointer px-4 py-2"
                                    >
                                        Save
                                    </button>
                                }
                            </div>
                            <div className="w-full flex gap-[3rem] px-[2rem] pt-[1rem] pb-[3rem] max-lg:grid max-lg:grid-cols-2">
                                <div className="flex flex-col gap-[1rem] flex-[2]">
                                    <div className="px-4">
                                        <Select
                                            className='w-full py-2 text-gray-700'
                                            placeholder='Select Main Category'
                                            value={mainCategorySport?.value ? mainCategorySport : ""}
                                            styles={styles}
                                            options={mainCategoryOptions}
                                            isClearable
                                            onChange={(selectedOption) => setMainCategorySport(selectedOption)}
                                            isDisabled={tournamentStatus === "Active" || tournamentStatus === "Completed"}
                                        >
                                        </Select>
                                        {/* )} */}
                                        {!mainCategorySport?.value && showError && (
                                            <p className="text-sm text-red-500">
                                                This field is required
                                            </p>
                                        )}
                                    </div>
                                    {/*Select Type DropDown*/}
                                    <div className="px-4">
                                        <Select
                                            placeholder='Select Type'
                                            className='w-full py-2 text-gray-700'
                                            styles={styles}
                                            value={selectType?.value ? selectType : ""}
                                            options={selectTypeOptions}
                                            isDisabled={true}
                                            onChange={(selectedOption) => setSelectType(selectedOption)}
                                        >
                                        </Select>
                                        {!selectType?.value && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                    {/*Select Format DropDown*/}
                                    <div className="px-4">
                                        <Select
                                            placeholder='Select Format'
                                            className='w-full py-2 text-gray-700'
                                            value={formatType?.value ? formatType : ""}
                                            styles={styles}
                                            options={selectFormatOptions}
                                            onChange={(selectedOption) => setFormatType(selectedOption)}
                                            isDisabled={true}
                                        >
                                        </Select>
                                        {!formatType?.value && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                    {/*Fixing Type DropDown*/}
                                    <div className="px-4">
                                        <Select
                                            placeholder='Fixing Type'
                                            className='w-full py-2 text-gray-700'
                                            value={fixingType?.value ? fixingType : ""}
                                            options={fixingTypeOptions}
                                            styles={styles}
                                            onChange={(selectedOption) => setFixingType(selectedOption)}
                                        // disabled = {status === "PENDING" ? false : true}
                                        >
                                        </Select>
                                        {!fixingType?.value && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                    <div className="px-4">
                                        <div className="flex items-center border rounded px-2 py-1">
                                            <input
                                                type="date"
                                                className="w-full outline-none"
                                                value={handleStartDate}
                                                onChange={(e) => setHandleStartDate(e.target.value)}
                                                placeholder="Start Date"
                                            />
                                            {/* {handleStartDate !== "" ? (
                                                <RxCross2
                                                    className="text-[1.2rem] text-red-600 border border-red-600 cursor-pointer"
                                                    onClick={() => setHandleStartDate("")}
                                                />
                                            ) : ""} */}
                                        </div>
                                        {!handleStartDate && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                    <div className="px-4">
                                        <textarea
                                            className="w-full p-3 mt-3 bg-[#F6F7FB] outline-none"
                                            placeholder="About Tournament"
                                            value={aboutTournament}
                                            rows={8}
                                            onChange={(e) => setAboutTournament(e.target.value)}
                                        />
                                        {!aboutTournament && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-[1.5rem] flex-[2]">
                                    {/*Sub Category Drop Down*/}
                                    <div className="px-4">
                                        <Select
                                            placeholder='Select Sub Category'
                                            className='w-full py-2 text-gray-700'
                                            value={subCategorySport?.value ? subCategorySport : ""}
                                            options={subCategoryOptions}
                                            styles={styles}
                                            onChange={(selectedOption) => setSubCategorySport(selectedOption)}
                                            isDisabled={tournamentStatus === "Active" || tournamentStatus === "Completed"}
                                        >
                                        </Select>
                                        {!subCategorySport?.value && showError && (
                                            <p className="text-sm text-red-500">
                                                This field is required
                                            </p>
                                        )}
                                    </div>
                                    <div className="px-4">
                                        <input
                                            type="text"
                                            className='w-full font-[500] py-2 px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none'
                                            placeholder={`${selectType?.value === "teamMatch" ? "Enter No. of Teams" : "Enter No. of Players"}`}
                                            value={participants}
                                            onChange={(e) => setParticipants(e.target.value)}
                                        // disabled = {status === "PENDING" ? false : true}
                                        />
                                        {!participants && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                    {/*Select Sport*/}
                                    <div className="px-4">
                                        <Select
                                            placeholder='Select Sport'
                                            className='w-full py-2 text-gray-700'
                                            value={selectSport?.value ? selectSport : ""}
                                            options={selectSportOptions}
                                            styles={styles}
                                            onChange={(selectedOption) => setSelectSport(selectedOption)}
                                            isDisabled={tournamentStatus === "Active" || tournamentStatus === "Completed"}
                                        >
                                        </Select>
                                        {!selectSport?.value && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                    <div className="px-4">
                                        <input
                                            type="text"
                                            className="w-full font-[500] py-[0.4rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                            placeholder="Tournament Name"
                                            value={tournamentName}
                                            onChange={(e) => setTournamentName(e.target.value)}
                                        />
                                        {!tournamentName && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                    <div className="px-4">
                                        <div className="flex items-center border rounded px-2 py-1">
                                            <input
                                                type="date"
                                                className="w-full outline-none"
                                                value={handleEndDate}
                                                placeholder="End Date"
                                                onChange={(e) => setHandleEndDate(e.target.value)}
                                            />
                                            {/* {handleEndDate !== "" ? (
                                                <RxCross2
                                                    className="text-[1.2rem] text-red-600 border border-red-600 cursor-pointer"
                                                    onClick={() => setHandleEndDate("")}
                                                />
                                            ) : ""} */}
                                        </div>
                                        {!handleEndDate && showError && (
                                            <p className="text-sm text-red-500">
                                                *This field is required
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col w-[30%] gap-[1.5rem] max-lg:w-[100%]">
                                    <div className="w-[100%] h-[235px] flex flex-col items-center justify-center bg-[#F4F5FA]">
                                        <div>
                                            <div className="flex justify-center items-center">
                                                <img src={uploadImage} className="w-10 " alt="upload img" />
                                            </div>
                                            <div
                                                className="text-primary flex items-center gap-3 cursor-pointer justify-center"
                                                onClick={triggerFileInput}
                                            >
                                                <MdCloudUpload className="w-[2rem] h-[2rem]" />
                                                <p className="max-lg:text-[14px]">Upload Banner</p>
                                            </div>
                                            <p className="text-[#808080] text-sm text-center">
                                                Upload an image of Tournament
                                            </p>
                                            <p className="text-[0.8rem] text-[#808080] text-center">
                                                File Format <span className="text-black"> jpeg, png </span>
                                                <br />
                                                Recommended Size{" "}
                                                <span className="text-black"> 600x600 (1:1) </span>
                                            </p>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                // accept="image/*"
                                                multiple
                                                className="hidden"
                                                onChange={handleFileInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 w-[100%] gap-10">
                                        {previewImage?.map((url, i) => {
                                            return (
                                                <div className="flex w-full h-[100px]" key={i}>
                                                    <div className="border border-gray-300 px-2 py-1">
                                                        <img
                                                            src={loadImageUrl(url)}
                                                            // alt="Selected"
                                                            className="w-[100%] h-full" // Adjust width to make space for the delete button
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <RiDeleteBinFill
                                                        className="text-[1.5rem] text-red-700 cursor-pointer"
                                                        onClick={() => handleImageDelete(i)}
                                                    />
                                                </div>
                                            );
                                        })}
                                        {selectedFile?.length === 0 && showError && (
                                            <p className="text-sm text-red-600 mt-[-12px]">*This field is required</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            </div>
            {/* )} */}
            <ToastContainer />
        </>
    );
}