import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { NavLink, useOutletContext } from "react-router-dom";
import moment from "moment";
import { APIurls } from "../../api/apiConstant";
import { useQuery } from "react-query";
import { useAuth } from "../../store/AuthContext";
import { RxCross2 } from "react-icons/rx";
import TournamentDeleteModal from "./TournamentDeleteModal";
import { ToastContainer } from "react-toastify";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

export default function Ongoing() {
  const ongoing = "ongoing";
  const { getAccessToken, userRole } = useAuth();
  const [searchInput, setSearchInput] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [tournamentId, setTournamentId] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(searchInput);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [Club, mainCategorySport, subCategorySport, sport] = useOutletContext();

  const fetchOngoingTournaments = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.fetchAllTournamentsBasedOnStatus}/${ongoing}?clubOwnerID=${Club}&mainCategoryID=${mainCategorySport}&subCategoryID=${subCategorySport}&sportID=${sport}&page=${currentPage}&limit=${limit}&searchID=${debouncedSearch}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message);
      }
      const result = await response.json();
      return result?.response;
    } catch (error) {
      console.log("Error while fetching ongoing tournaments", error.message);
    }
  };
  const {
    data: ongoingTournaments,
    isLoading,
    refetch,
  } = useQuery(
    [
      "ongoingTournamentsData",
      ongoing,
      Club,
      mainCategorySport,
      subCategorySport,
      sport,
      currentPage,
      limit,
      debouncedSearch,
    ],
    fetchOngoingTournaments
  );

  const handleDeleteTournamentModal = (id) => {
    setTournamentId(id);
    setDeleteModal(true);
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value?.toUpperCase());
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Tournament ID",
      selector: (row) => <div>{row?.tournamentID}</div>,
      center: true,
      grow: 2.7,
    },
    {
      name: "Title",
      selector: (row) => <div>{row?.title}</div>,
      center: true,
      grow: 2.5,
    },
    {
      name: "Club Name",
      selector: (row) =>
        row?.clubNames?.map((item, index) => (
          <div key={index}>
            <p>{item}</p>
          </div>
        )),
      center: true,
      grow: 3.5,
    },
    {
      name: "No.of Venues",
      selector: (row) => <div>{row?.venue}</div>,
      center: true,
      grow: 2.8,
    },
    {
      name: "Category",
      selector: (row) => (
        <div>
          <p>{row?.category?.mainCategory}</p>
          <p>{row?.category?.subCategory}</p>
          <p>{row?.category?.sportName}</p>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Type of Game",
      selector: (row) => <div>{row?.gameType}</div>,
      center: true,
      grow: 2.5,
    },
    {
      name: "Format",
      selector: (row) => <div>{row?.tournamentType}</div>,
      center: true,
      grow: 1.5,
    },
    {
      name: "Players",
      selector: (row) => <p>{row?.participants}</p>,
      center: true,
    },
    {
      name: "Duration",
      selector: (row) => (
        <div>
          <p>
            {row?.duration?.start === null
              ? "NA"
              : moment(row?.duration?.start).format("MMM Do YY")}
          </p>
          <p>
            {row?.duration?.end === null
              ? "NA"
              : moment(row?.duration?.end).format("MMM Do YY")}
          </p>
        </div>
      ),
      center: true,
      grow: 2.5,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[7rem] ${
            row?.tournamentStatus === "PENDING"
              ? "text-[#FFCE06] bg-[#F1ECE3]"
              : row?.tournamentStatus === "ACTIVE"
              ? "text-[#25BF17] bg-[#D1F0CF]"
              : "text-[#FF0023] bg-[#FDCAD1]"
          }`}
        >
          <span
            className={`${
              row?.tournamentStatus === "PENDING"
                ? "bg-[#FFCE06]"
                : row?.tournamentStatus === "ACTIVE"
                ? "bg-[#25BF17]"
                : "bg-[#FF0023]"
            } h-[0.5rem] w-[0.5rem] rounded-full`}
          ></span>
          <h2 className="text-[13px]">{row?.tournamentStatus}</h2>
        </div>
      ),
      grow: 3.3,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-[0.3rem]">
          <NavLink to={`/tournaments/tournamentDetails/${row?.tournamentID}`}>
            <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer">
              <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
            </div>
          </NavLink>
          <NavLink
            to={`/tournaments/tournamentDetails/${row?.tournamentID}/editOverViewTournament`}
          >
            <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer">
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem] text-black"
              />
            </div>
          </NavLink>
          {!userRole?.role?.staff?.type && (
            <div
              className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer"
              onClick={() => handleDeleteTournamentModal(row?.tournamentID)}
            >
              <Icon
                icon="material-symbols:delete-rounded"
                className="text-[1.5rem] text-black"
              />
            </div>
          )}
        </div>
      ),
      center: true,
      grow: 3.5,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },
    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  // Update debounced search after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchInput);
    }, 1000);

    // Clean up timer if the search term changes
    return () => clearTimeout(handler);
  }, [searchInput]);
  
  const onChangePage = (direction) => {
    if (direction === "left") {
      if (currentPage === 1 || currentPage === 0) {
        return;
      }
      setCurrentPage(currentPage - 1);
    }
    if (direction === "right") {
      if (currentPage === ongoingTournaments?.totalPages) {
        return;
      }
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="p-6">
        <div className="flex justify-between">
          <p className="text-xl font-semibold">Ongoing Tournaments</p>
          <div className="flex gap-4">
            <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
              <input
                type="text"
                className="w-[16rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                placeholder="Search by Tournament ID or Title"
                value={searchInput}
                onChange={handleSearch}
              />
              {searchInput !== "" ? (
                <RxCross2
                  className="text-[20px] text-white bg-primary cursor-pointer rounded-md"
                  onClick={() => setSearchInput("")}
                />
              ) : (
                <Icon
                  icon="ri:search-2-fill"
                  className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
                />
              )}
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={ongoingTournaments?.tournaments}
          customStyles={customStyles}
          // pagination
          // paginationPerPage={10}
          progressPending={isLoading}
        />
        <div className="mt-2">
          <div className="flex justify-center items-center">
            <p>
              <label>Total Tournaments : </label>
              <span>{ongoingTournaments?.totalTournaments}</span>
            </p>
          </div>
          <div className="flex gap-3 justify-center items-center text-[#858585] font-medium">
            <div>
              <label className="text-[13px]">Rows per page:</label>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(+e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                {/* <option value={1000}>{1000}</option> */}
              </select>
            </div>
            <div className="flex gap-1 justify-center items-center ">
              {/* left icon stop*/}
              {/* left icon */}
              <FaAngleDoubleLeft
                size={25}
                className={`${
                  currentPage === 1 || currentPage === 0
                    ? "cursor-not-allowed  text-blue-gray-300"
                    : "cursor-pointer active:size-6 active:text-blue-gray-700 text-black"
                } mr-2`}
                onClick={() => onChangePage("left")}
              />
              <span className="font-bold">
                {ongoingTournaments?.totalPages === 0 ? 0 : currentPage}
              </span>{" "}
              /{" "}
              <span className="font-bold">
                {ongoingTournaments?.totalPages}
              </span>
              {/* right icon */}
              <FaAngleDoubleRight
                size={25}
                className={`${
                  currentPage === ongoingTournaments?.totalPages ||
                  currentPage === 0
                    ? "cursor-not-allowed text-blue-gray-300"
                    : " cursor-pointer active:size-6 active:text-blue-gray-700 text-black"
                } ml-2`}
                onClick={() => onChangePage("right")}
              />
            </div>
          </div>
        </div>
        <ToastContainer />
        {deleteModal && (
          <TournamentDeleteModal
            deleteModal={setDeleteModal}
            tournamentId={tournamentId}
            refetch={refetch}
          />
        )}
      </div>
    </>
  );
}
