import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PulseLoader } from "react-spinners";
import Select from "react-select";
import { RotatingLines } from "react-loader-spinner";

export default function EditMatchDetailsModal({
  setMatchDetailsModal,
  matchID,
  setIsRefetch,
}) {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [venue, setVenue] = useState({ value: "", label: "" });
  const [referee, setReferee] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [venueOptions, setVenueOptions] = useState([]);

  const { getAccessToken } = useAuth();
  const { tournamentId } = useParams();

  const fetchVenuesData = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchVenues}/${tournamentId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    const data = result?.response?.venues?.map((item) => {
      return {
        value: item?._id,
        label: item?.venueName,
      };
    });
    setVenueOptions(data)
  };

  const fetchMatchDetails = async () => {
    setLoader(true);
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchMatchDetailsById}/${matchID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    const matchData = result?.response?.matchDetails;
    console.log("MATCH DETAILS FOR EDIT MATCH : ", result);
    const convertedDate = new Date(matchData?.dateOfPlay);
    setDate(convertedDate?.toISOString().split("T")[0]);
    if (matchData?.venue?.id) {
      setVenue({
        value: matchData?.venue?.id,
        label: matchData?.venue?.name,
      });
    }
    setReferee(matchData?.referee);
    const newDate = new Date(matchData?.timing);
    if (newDate?.getHours() >= 10) {
      setTime(newDate?.getHours() + ":" + newDate?.getMinutes());
    } else {
      setTime("0" + newDate?.getHours() + ":" + newDate?.getMinutes());
    }
    setLoader(false);
  };

  useEffect(() => {
    (async () => {
      fetchVenuesData();
      await fetchMatchDetails();
    })()
  }, [])

  const handleSave = async (e) => {
    e.preventDefault();
    if (!date || !time || !venue?.value) {
      setShowError(true);
      return;
    }
    const timing = time.split(":");
    const dateTime = new Date(date);
    // Set the hours and minutes
    dateTime?.setHours(+timing[0]); // Set hours to 15 (3 PM)
    dateTime?.setMinutes(+timing[1]); // Set minutes to 30

    const details = {
      date: date,
      time: dateTime,
      venueId: venue?.value,
      refreeName: referee,
    };
    setIsLoading(true);
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.EditMatchDetailsInGameFixtures}/${matchID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(details),
        }
      );
      const result = await response.json();
      if (result?.code > 201) {
        toast.error(result?.message);
        throw new Error(result?.message);
      }
      console.log("Match Details Updated Successfully", result);
      setIsRefetch(true);
      setMatchDetailsModal(false);
      setTimeout(() => { toast.success("Match Details Updated Successfully") }, 1500)
    } catch (error) {
      console.log("Error in saving match details ", error?.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[40%] relative">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">Edit Details</h1>
          <div
            onClick={() => setMatchDetailsModal(false)}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm cursor-pointer"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </div>
        </div>
        {loader ? <div className="flex justify-center items-center">
          <RotatingLines
            visible={true}
            height="35"
            width="35"
            color="gray"
            strokeColor="#FB6108"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
          :
          <form onSubmit={handleSave}>
            <div className="flex items-center justify-center gap-[3rem]">
              <div className="flex flex-col gap-5">
                <div className="w-full">
                  <input
                    type="date"
                    value={date}
                    className="outline-none border px-4 py-2 rounded-md w-[250px]"
                    onChange={(e) => setDate(e.target.value)}
                  />
                  {!date && showError && (
                    <p className="text-xs text-red-500">
                      *This field is required
                    </p>
                  )}
                </div>
                <div>
                  <Select
                    options={venueOptions}
                    placeholder="Select Venue"
                    className="w-[250px] px-1 py-2 text-gray-700 rounded-md outline-none"
                    value={venue?.value ? venue : ""}
                    isClearable={true}
                    onChange={(selectedOption) => setVenue(selectedOption)}
                  ></Select>
                  {venue?.value === "" && showError && (
                    <p className="text-red-600 text-xs">
                      This field is required*
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <div>
                  <input
                    type="time"
                    className="outline-none border-2 w-full p-2 rounded-md"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                  {!time && showError && (
                    <p className="text-red-600 text-xs">
                      This field is required*
                    </p>
                  )}
                </div>
                <input
                  className="w-[100%] rounded-md text-base border-2 font-[500] py-2 px-[1rem] placeholder:text-[#7F7F7F] outline-none"
                  size="lg"
                  placeholder="Enter Referee"
                  type="text"
                  value={referee}
                  onChange={(e) => setReferee(e.target.value)}
                />
              </div>
            </div>
            <div className="w-[100px] flex justify-center text-white bg-[#FB6108] py-2 mt-[20px] cursor-pointer rounded-md gap-1">
              {isLoading ? (
                <PulseLoader color="white" size={8} />
              ) : (
                <div className="flex justify-center items-center gap-1 ">
                  <button type="submit" className="font-semibold">
                    Save
                  </button>
                  <PiGreaterThan />
                </div>
              )}
            </div>
          </form>
        }
      </div>
    </div>
  );
}
