import React from "react";
import { Icon } from "@iconify/react";

export default function ActivityRemarkModel({
  setActivityModal,
  remark,
}) {
  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[30%] relative">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">Activity Remark</h1>
          <button
            onClick={() => setActivityModal(false)}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </button>
        </div>
        <div className="overflow-y-auto h-[70px]">
          <p className="text-center">{remark}</p>
        </div>
      </div>
    </div>
  );
}
